import React from "react";

const Step6 = ({ currentStep, nextFunc }) => {
  if (currentStep === 6) {
    return (
      <fieldset>
        <div className="overlay" />
        <img className="lazy" src="assets/4.png" style={{ display: "block" }} />
        <div className="page_blocks">
          <div>
            Do you need to edit a file or create a new one.
            You can do this right out of HQ.APP.
            Just double click the file you want to edit
            and all your changes will be
            automatically synced!
          </div>
          <button type="button" className="cta-button" onClick={nextFunc}>
            Edit a document
          </button>
        </div>
      </fieldset>
    );
  } else {
    return null;
  }
};

export default Step6;
