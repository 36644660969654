import * as React from "react";
import injectSheet from "react-jss";
import classNames from 'classnames';
import Layout from '../components/layout';
import Discover from './home/discover';
import Page from '../components/Page/Page';
import FloatingTabs from "../components/FloatingTabs";
import FloatingTab from "../components/FloatingTabs/FloatingTab";
import UseCaseTutorial from "../components/UseCaseTutorial";

const styles = {
    useCases: {
      textAlign: 'center',
      width: '100%',
      margin: '24px auto',

      "& > div:first-of-type": {
        "@media (max-width: 767px)": {
          display: 'none'
        }
      }
    },
    useCase: {
      margin: '24px auto',
      maxWidth: '600px',
      backgroundColor: '#fff',
      boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
      padding: '24px 16px',
      color: '#999'
    }
};

interface UseCasesProps {
  classes?: any,
  location?: any
}

const UseCases = ({classes: c, location}: UseCasesProps) => {
  const [useCase, setUseCase] = React.useState(1)

  return (
    <Layout location={location}>
      <Page title={'Ways to use HQ'} subtitle={'Check how it works'}>
        <div className={classNames(c.useCases, 'use-cases')}>
          <FloatingTabs onTabClick={setUseCase}>
            <FloatingTab id={1} label="Consulting" />
            <FloatingTab id={2} label="Design Agency" />
            <FloatingTab id={3} label="Engineering (CTO)" />
            <FloatingTab id={4} label="Operations (COO)" />
            <FloatingTab id={5} label="Marketing & Sales (CM0)" />
            <FloatingTab id={6} label="CoResearch & Educationnsulting" />
            <FloatingTab id={7} label="Journalism & Writing" />
            <FloatingTab id={8} label="Accountancy & Law" />
          </FloatingTabs>
          {
            useCase === 1 ? <UseCaseTutorial /> : undefined
          }
          {
            useCase !== 1 ? <div className={c.useCase}>Coming soon</div> : undefined
          }
        </div>
      </Page>
      <Discover />
    </Layout>
  )
}

export default injectSheet(styles)(UseCases);
