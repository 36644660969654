import React from "react";

const Step2 = ({ currentStep, nextFunc }) => {
  if (currentStep === 2) {
    return (
      <fieldset>
        <div className="overlay" />
        <img className="lazy first_img" src="assets/2.png" alt="Img" />
        <div className="page_blocks">
          <div>
              You remember collaborating
              on the presentation in Google
              Drive but then some
              adjustments were made and
              somebody has send it through
              email.
          </div>
          <button type="button" className="cta-button" onClick={nextFunc}>Use the magic
              search!
          </button>
        </div>
      </fieldset>
    );
  } else {
    return null;
  }
};

export default Step2;
