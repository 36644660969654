import React from "react";

const Steps = ({ currentStep }) => {
  return (
      <div className="steps">
          <div>step {currentStep} of 8</div>
      </div>
  );
};

export default Steps;
