import React from "react";

const Step7 = ({currentStep, nextFunc}) => {
    let videoPlay = null;
    let showDiv = false;

    function handleClick() {
        videoPlay.play();
        document.getElementById("stepthree_overlay").style.display = "none";
        document.getElementById("stepthree_box").style.display = "none";
    }

    let style = {
        display: "none"
    };

    function handleVideoEnd() {
        showDiv = true;
        document.getElementById("stepthree_overlay").style.display = "block";
        document.getElementById("stepthree_box").style.display = "block";
    }

    if (currentStep === 7) {
        return (
            <fieldset>
                <div className="overlay" style={style} id="stepthree_overlay"/>
                <video
                    id="third_video"
                    autoPlay
                    ref={vidRef => {
                        videoPlay = vidRef;
                    }}
                    onEnded={handleVideoEnd}
                >
                    <source src="assets/videos/3.mp4" type="video/mp4"/>
                    Your browser does not support video.
                </video>
                <div
                    className="third_video_end_block video_end_block page_blocks"
                    style={style}
                    id="stepthree_box"
                >
                    <div>
                        Wow, You can edit any document from any of your connected
                        accounts.
                        Without downloading or installing any additional software!
                    </div>
                    <button type="button" className="replaybutton" onClick={handleClick}>
                        <div>Replay video</div>
                    </button>
                    <br/>
                    <button className="next cta-button" onClick={nextFunc}>I want to learn more</button>
                </div>
            </fieldset>
        );
    } else {
        return null;
    }
};

export default Step7;
