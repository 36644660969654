import React from "react";
import Step1 from "./../steps/step1";
import Step2 from "./../steps/step2";
import Step3 from "./../steps/step3";
import Step4 from "./../steps/step4";
import Step5 from "./../steps/step5";
import Step6 from "./../steps/step6";
import Step7 from "./../steps/step7";
import Step8 from "./../steps/step8";
import Steps from "./steps";

const WizardFormContainer = ({ currentStep, nextFunc }) => {
  return (
    <div className="container" id="container">
      <span className="warning">Best viewed on a full-screen desktop</span>
      <Steps currentStep={currentStep} />
      <Step1 currentStep={currentStep} nextFunc={nextFunc} />
      <Step2 currentStep={currentStep} nextFunc={nextFunc} />
      <Step3 currentStep={currentStep} nextFunc={nextFunc} />
      <Step4 currentStep={currentStep} nextFunc={nextFunc} />
      <Step5 currentStep={currentStep} nextFunc={nextFunc} />
      <Step6 currentStep={currentStep} nextFunc={nextFunc} />
      <Step7 currentStep={currentStep} nextFunc={nextFunc} />
      <Step8 currentStep={currentStep} nextFunc={nextFunc} />
    </div>
  );
};

export default WizardFormContainer;
