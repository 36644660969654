import React from "react";

const Step4 = ({ currentStep, nextFunc }) => {
  if (currentStep === 4) {
    return (
      <fieldset>
        <div className="overlay" />
        <img className="lazy" src="assets/3.png" style={{ display: "block" }} />
        <div className="page_blocks">
          <div>
            Well done Jeff!
            Your kickoff presentation was amazing.
            The customer wants you to start immediately.
            They prepared you a new Dropbox account
            and already shared 100's of files.
          </div>
          <button type="button" className="cta-button" onClick={nextFunc}>
            Add an extra account!
          </button>
        </div>
      </fieldset>
    );
  } else {
    return null;
  }
};

export default Step4;
