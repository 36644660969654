import React from "react";

const Step3 = ({ currentStep, nextFunc }) => {
  let videoPlay = null;
  let showDiv = false;

  function handleClick() {
    videoPlay.play();
    document.getElementById("stepthree_overlay").style.display = "none";
    document.getElementById("stepthree_box").style.display = "none";
  }

  let style = {
    display: "none"
  };

  function handleVideoEnd() {
    showDiv = true;
    document.getElementById("stepthree_overlay").style.display = "block";
    document.getElementById("stepthree_box").style.display = "flex";
  }
  if (currentStep === 3) {
    return (
      <fieldset>
        <div className="overlay" style={style} id="stepthree_overlay" />
        <video
          className="background-blue"
          autoPlay
          id="first_video"
          ref={vidRef => {
            videoPlay = vidRef;
          }}
          onEnded={handleVideoEnd}
        >
          <source src="assets/videos/1.mp4" type="video/mp4" />
          Your browser does not support video.
        </video>
        <div
          className="first_video_end_block video_end_block page_blocks"
          style={style}
          id="stepthree_box"
        >
          <div>
            Well done Jeff!
            
              Your kickoff presentation was amazing.
            
          </div>
          <br />
          <button type="button" className="replaybutton" onClick={handleClick}>
            <div>Replay video</div>
          </button>
          <br />
          <button
            className="next cta-button"
            onClick={nextFunc}
          >See when you can start</button>
        </div>
      </fieldset>
    );
  } else {
    return null;
  }
};

export default Step3;
