import React from "react";

const Step1 = ({ currentStep, nextFunc }) => {
  if (currentStep === 1) {
    return (
      <fieldset>
        <div className="overlay" />
        <img className="lazy first_img" src="assets/1.png" alt="Img" />
        <div className="page_blocks">
          
            <div>
                Your name is jeff, you are a
                counsultant on your way to the
                kickoff meeting at a new 
                customer.
                It's time to open your
                presentation but where did you
                save the presentation?
            </div>
          
          <button type="button" className="cta-button" onClick={nextFunc}>
            Find the Presentation
          </button>
        </div>
      </fieldset>
    );
  } else {
    return null;
  }
};

export default Step1;
