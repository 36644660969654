import React, { Component } from "react";
import ProgressBar from "./partials/progressBar";
import WizardFormContainer from "./partials/wizardFormContainer";
import "./style/style.css";
import "./style/custom.css";

class UseCaseTutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 1
    };

    this._next = this._next.bind(this);
  
  }

  componentDidMount ()
  {
    // console.log(document.getElementById("container").offsetHeight);
    // document.getElementById("main-container").style.height = (document.getElementById("overlay_unique").offsetHeight + 50 )+'px';
  }


  _next() {
    let currentStep = this.state.currentStep;
    // Make sure currentStep is set to something reasonable
    if (currentStep >= 7) {
      currentStep = 8;
    } else {
      currentStep = currentStep + 1;
    }

    this.setState({
      currentStep: currentStep
    });
  }

  render() {
    return (
      <>
        <div id="msform">
          <div className="main-container" id="main-container">
            <div className="overlay" style={{backgroundColor: 'transparent'}} id="overlay_unique"></div>
            <WizardFormContainer
              currentStep={this.state.currentStep}
              nextFunc={this._next}
            />
          </div>
        </div>
        <div id="not-supported" className="not-supported-block">
          <div className="not-supported-block__title">
            We are very sorry...
          </div>
          <div className="not-supported-block__content">
            The tutorial about how you can use HQ is best recommended on larger screens.
          </div>
        </div>
      </>
    );
  }
}

export default UseCaseTutorial;
