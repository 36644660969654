import React from "react";
import axios from "axios";

const Step8 = ({ currentStep, nextFunc }) => {
  let email = "";

  let style = {
    display: "none"
  };

  function emailRequest() {
    email = document.getElementById("input_email").value;

    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
      document.getElementById("Submitting-overlay").style.display = "block";
      axios
        .post(
          "https://hooks.zapier.com/hooks/catch/1202079/oyrlwh0/",
          {
            email
          },
          { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        )
        .then(res => {
          document.getElementById("Submitting-overlay").style.display = "none";
          document.getElementById("Submitted-overlay").style.display = "block";
        })
        .catch(err => {
          console.log(err);
          alert("Error!");
        });
    } else {
      alert("Email is not correct!");
    }
  }

  if (currentStep === 8) {
    return (
      <fieldset>
        <div className="overlay" />
        <img className="lazy" src="assets/5.png" alt="image" style={{ display: "block" }} />
        <div className="page_blocks last-field">
          <div>
            <div id="Submitting-overlay" style={style}>
              <img src="assets/submitting.gif" alt="image" className="loading" />
            </div>
            <div id="Submitted-overlay" style={style}>
              <img src="assets/tick.png" alt="image" className="sent" />
              <div>Submitted!!!</div>
            </div>
              <div className="email-block">
                <b>You are almost a</b>
                <b>document management superstart!</b>
                Create your own free HQ.APP account and start
                managing all your documents in one place!
                <input
                  type="text"
                  className="input_email"
                  placeholder="Email"
                  id="input_email"
                />
              </div>
          </div>
          <button
            className="cta-button add_email"
            style={{ width: 80 + "%" }}
            onClick={emailRequest}
          >
            Create my free account
          </button>
        </div>
      </fieldset>
    );
  } else {
    return null;
  }
};

export default Step8;
