import React from 'react';
import injectSheet from 'react-jss'
import HQTheme from '../../theme/theme';

const styles = {
    floatingTab: {
        backgroundColor: (props: any) => props.activeTab === props.id ? HQTheme.palette.primary.main : HQTheme.palette.tertiary.main,
        boxShadow: '0 3px 6px rgba(0,0,0,0.1), 0 3px 6px rgba(0,0,0,0.1)',
        cursor: 'pointer',
        padding: '8px 16px',
        borderRadius: '4px',
        margin: '0 8px',
        textAlign: 'center',
        color: (props: any) => props.activeTab === props.id ? HQTheme.palette.text.primary : HQTheme.palette.text.primaryContrast,
        transition: 'all 300ms linear',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '16px',

        '&:hover': {
            boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
        }
    }
}

interface FloatingTabProps {
    id: number
    onClick: (id: number) => void
    classes: any
    label: string,
    activeTab: number,
    children: any
}

const FloatingTab = ({ classes: c, activeTab, label, id, onClick }: FloatingTabProps) => {
    const handleClick = () => {
        onClick(id);
    }

    return (
        <div className={c.floatingTab} onClick={handleClick}>
            {label}
        </div>
    )
}

export default injectSheet(styles)(FloatingTab);