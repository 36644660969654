import React, { useState } from 'react';
import injectSheet from 'react-jss'
import DragScroll from 'react-dragscroll'

const styles = {
    floatingTabsWrapper: {
        width: '100%',
        overflowY: 'auto'
    },
    floatingTabs: {
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
        padding: '0 16px 16px 16px',
        margin: 'auto',
        flexWrap: 'wrap'
    }
}

interface FloatingTabsProps {
    classes: any,
    children: any,
    onTabClick: (tab: number) => void
}

const FloatingTabs = ({ classes: c, children, onTabClick }: FloatingTabsProps) => {
    const [activeTab, setActiveTab] = useState(children[0].props.id)

    const onClickTabItem = (tab: any) => {
        setActiveTab(tab);
        onTabClick(tab)
    }
    
    const wiredChildren = React.Children.map(children, child => {
       return React.cloneElement(child, { activeTab, onClick: onClickTabItem })
    })
    
    return (
        <DragScroll className={c.floatingTabsWrapper}>
            <div className={c.floatingTabs}>
                {wiredChildren}
            </div>
        </DragScroll>
    )
}

export default injectSheet(styles)(FloatingTabs);